import img1 from "../../Assestes/PhotoGallery/BalniketanDanceGroup.jpg";
import img2 from "../../Assestes/PhotoGallery/BananaGirl.jpg";
import img3 from "../../Assestes/PhotoGallery/Charoo.jpg";
import img4 from "../../Assestes/PhotoGallery/ColorfulClothStore.jpg";
import img5 from "../../Assestes/PhotoGallery/CoolieDelhiStation.jpg";
import img6 from "../../Assestes/PhotoGallery/DillyHutPerformerHorse.jpg";
import img8 from "../../Assestes/PhotoGallery/FireEaterDelhi.jpg";
import img9 from "../../Assestes/PhotoGallery/FirstTasteStreetChildern.jpg";
import img10 from "../../Assestes/PhotoGallery/GirlsChorus.jpg";
import img11 from "../../Assestes/PhotoGallery/GKKLivingRoomIndianShawl.jpg";
import img12 from "../../Assestes/PhotoGallery/Heritage5.jpg";
import img13 from "../../Assestes/PhotoGallery/Indra-Mom-Garden.jpg";
import img14 from "../../Assestes/PhotoGallery/IndraFirstDayKids1.jpg";
import img15 from "../../Assestes/PhotoGallery/InduKids1.jpg";
import img16 from "../../Assestes/PhotoGallery/MyManInPatiala.jpg";
import img17 from "../../Assestes/PhotoGallery/Papaya.jpg";
import img18 from "../../Assestes/PhotoGallery/PublicPunishment.jpg";
import img19 from "../../Assestes/PhotoGallery/Parrot.jpg";
import img20 from "../../Assestes/PhotoGallery/Pickles.jpg";
import img21 from "../../Assestes/PhotoGallery/RoseIndu1.jpg";
import img22 from "../../Assestes/PhotoGallery/SardarjiDelhiStation1.jpg";
import img23 from "../../Assestes/PhotoGallery/ScooterFamily.jpg";
import img24 from "../../Assestes/PhotoGallery/SikhBoys.jpg";
import img25 from "../../Assestes/PhotoGallery/StreetKids.jpg";
import img26 from "../../Assestes/PhotoGallery/ValentineRose.jpg";
import img27 from "../../Assestes/PhotoGallery/Wedding-Chaudhary.jpg";
import img28 from "../../Assestes/PhotoGallery/WeddingWalkingAway.jpg";
import img29 from "../../Assestes/PhotoGallery/Welcome.jpg";
import img30 from "../../Assestes/PhotoGallery/WeddingGKKIndu.jpg";
import img31 from "../../Assestes/PhotoGallery/Kavita.jpg";
import img32 from "../../Assestes/PhotoGallery/MorningNews.jpg";
import img33 from "../../Assestes/PhotoGallery/SDSE2011.jpg";

export const photoGalleryData = [
  {
    src: img1,
    rows: 2,
    cols: 3,
   
  },
  {
    src: img2,
  },
 
  {
    src: img3,
  },
  {
    src: img31,
    cols: 2,
  },
  {
    src: img4,
    cols: 2,
  },
  {
    src: img5,
  },
  {
    src: img6,
    cols: 2,
  },
  {
    src: img8,
    rows: 2,

    cols: 2,
    
  },
  {
    src: img9,
    rows: 2,
    cols: 2,
  },
  {
    src: img10,
    cols: 2,

  },
  {
    src: img11,
    rows: 2,
    cols: 2,

  },
  {
    src: img12,
    cols: 2,
  },
  {
    src: img13,
    cols: 2,

  },
  {
    src: img14,
    cols: 2,
  },
  {
    src: img15,
  },
  {
    src: img16,
  },
  {
    src: img17,
    rows: 2,
    cols: 2,
  },
  {
    src: img33,
  },
  {
    src: img18,
  },
  {
    src: img19,
  },
  {
    src: img20,
  },
  {
    src: img21,
  },
  {
    src: img32,
  },
  {
    src: img22,
    rows: 2,
    cols: 2,
  },
  {
    src: img23,
    cols: 2,

  },
  {
    src: img24,
    cols: 2,
   
  },
  {
    src: img29,
    cols: 2,

  },
  {
    src: img26,
  },
  {
    src: img27,
  },
  {
    src: img30,
    rows: 2,
    cols: 3,
   
  },
  {
    src: img28,
    cols: 2,
    rows:2
  },
 
  {
    src: img25,
    cols: 3,
    rows:2

  },

];
