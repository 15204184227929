import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import { photoGalleryData } from "./PhotoGalleryData";
import classes from "./photoGallery.module.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const slides = photoGalleryData.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const PhotoGallery = () => {
  const [index, setIndex] = useState(-1);
  const [isSlider, setIsSlider] = useState(false);
  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "10px",
  };
  const handleClick = (index, item) => {
    console.log("check................", index);
    setIndex(index);
  };
  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
  const onchange = (e) => {
    setIsSlider(!isSlider);
    console.log("helll", e.target.value);
  };
  return (
    <div className={classes.photoContainer}>
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />

      {/* <Gallery
        images={photoGalleryData}
        onClick={handleClick}
        defaultContainerWidth={300}
    
        enableImageSelection={false}
      /> */}
      {/* <p className={classes.title}>Photos from Indian memories </p> */}

      <>
          {/* <div style={{ height: "10px" }}></div>{" "} */}
          <Slider {...settings}>
            {photoGalleryData.map((item, index) => {
              return (
                <div key={item.src}>
                  {/* <img src={item.src} className={classes.photo} alt="" /> */}
                  {item.src && (
                    <div
                      style={{ backgroundImage: `url(${item.src})` }}
                      className={classes.photo}
                    ></div>
                  )}
                </div>
              );
            })}
          </Slider>
          {/* <div style={{ height: "100px" }}></div> */}
        </>

    
        {/* <ImageList
          sx={{ width: "100%", height: "auto" }}
          variant="quilted"
          cols={8}
          rowHeight={200}
        >
          {photoGalleryData.map((item, index) => (
            <ImageListItem
              key={item.src}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.src, 200, item.rows, item.cols)}
                alt="{item.title}"
                loading="lazy"
                onClick={() => handleClick(index)}
                style={{ cursor: "pointer" }}
              />
            </ImageListItem>
        
        </ImageList> */}
     
    </div>
  );
};

export default PhotoGallery;
