import Indra4Kid from "../../Assestes/AboutMe/Photo-Bio-Indra-4Girls.jpg";
import IndraKid from "../../Assestes/AboutMe/Photo-Bio-Indra-Kid.jpg";
import IndraTahoe from "../../Assestes/AboutMe/Photo-Bio-IndraTahoe-Deck.jpg";
import IndraFacepaint from "../../Assestes/AboutMe/Photo-IndraKids-Facepaint.jpg";

export const aboutMeData = [
  {
    src: IndraTahoe,
    rows:2,
    cols:2
  },
  {
    src: Indra4Kid,
    cols:2

  },
  {
    src: IndraKid,
    cols:2
  },
  {
    src: IndraFacepaint,
    cols:4,
    rows:2
  },
  
];
