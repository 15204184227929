import React from "react";
import classes from "./review.module.scss";
import review from "../../Assestes/review.png";

const Review = () => {
  return (
    <div className={classes.reviewContainer}>
      <p className={classes.titleReview}>Reviews</p>

      <p>
        "Kapur is an incredible storyteller, took me to a place I had never
        been...truly a gifted writer. Kapur paints such vivid pictures of
        India...just perfect."<br/> - <span style={{fontStyle:"italic"}}>
        Leslie DeDora, creator of A Touch of
        Understanding, Inc.
          </span>
      </p>

      <p>
        "These short stories deftly capture the nuances and contradictions of
        their well-drawn characters, many from India, in a range of intriguing
        and often dramatic situations." <br/> - <span style={{fontStyle:"italic"}}>
          Jack Adler, author of "The Tides of
        Faith" and other novels.
          </span>
      </p>

      <img src={review} alt="" />
    </div>
  );
};

export default Review;
