import React from "react";
import classes from "./navbar.module.scss";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import close from "../../Assestes/close.png"

const Navbar = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "rgba(253,213,141)",
    boxShadow: 24,
    outline:"none",
    border:"none",
    p: 4,
  };
  return (
    <div className={classes.mainNavbar}>
      <div className={classes.namAndSlogan}>
        <div className={classes.name}>I.D. Kapur</div>
        {/* <div className={classes.slogan}>
          Author write short stories based on her unforgettable experiences.
        </div> */}
      </div>

      <div className={classes.navbarContainer}>
        <Link to="/">
          <p>Home</p>
        </Link>
        <Link to="/about">
          <p>About Me</p>
        </Link>

        <Link to="/reviews">
          {" "}
          <p>Reviews</p>
        </Link>
        <p onClick={handleOpen}>Contact</p>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="titleReach">
            Reach me at
          </p>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <a href="mailto:idkapur@inanygivenroom.com" className="mail">
              idkapur@inanygivenroom.com
            </a>
          </Typography>
        <img src={close} alt="" onClick={handleClose} className={classes.close} />

        </Box>
      </Modal>
    </div>
  );
};

export default Navbar;
