import React, { useState, useCallback } from "react";
import classes from "./aboutme.module.scss";
import { Gallery } from "react-grid-gallery";
import { aboutMeData } from "./AboutMeData";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ImageListItem from "@mui/material/ImageListItem";
import ImageList from "@mui/material/ImageList";
import Navbar from "../Navabar/Navbar";

const slides = aboutMeData.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

const AboutMe = () => {
  const [index, setIndex] = useState(-1);

  const handleClick = (index, item) => setIndex(index);
  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <div className={classes.aboutMeContainer}>
      <div className={classes.photoscontainer}>
        <Lightbox
          slides={slides}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
        />
        <ImageList
          sx={{ width: "90%", height: "auto" }}
          variant="quilted"
          cols={4}
          rowHeight={200}
        >
          {aboutMeData.map((item, index) => (
            <ImageListItem
              key={item.src}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.src, 200, item.rows, item.cols)}
                alt="{item.title}"
                loading="lazy"
                onClick={() => handleClick(index)}
                style={{ cursor: "pointer" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>

      <div className={classes.aboutMeText}>
        <p className={classes.aboutMeTitle}>About Me</p>
        I.D. Kapur majored in journalism and is a speech writer and prize-winning
        poet whose work is featured in the anthology <a style={{fontStyle:"italic"}} href="https://www.amazon.com/Ink-Spots-Award-winning-Fiction-Poetry/dp/B0B6S4SWLH/ref=sr_1_2" target="_blank">
        Ink Spots</a>.  She began
        traveling at 17 to Europe and has visited over twenty
        countries. A year’s stay in Northern India and over a dozen extended
        visits inspired her to write short stories based on her experiences. Her
        next book, <span  style={{fontStyle:"italic"}} >A Year Without Men</span> , will be published in 2023. She lives in
        Northern California with her author husband.
      </div>
    </div>
  );
};

export default AboutMe;
