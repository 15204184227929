import './App.css';
import Home from './Organisms/Home/Home';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AboutMe from './Moleclues/AboutMe/AboutMe';
import Navbar from './Moleclues/Navabar/Navbar';
import Review from './Moleclues/Review/Review';
import PhotoGallery from './Moleclues/PhotoGallery/PhotoGallery';

function App() {
  return (
    <div className="App">
     
      <BrowserRouter>
      <PhotoGallery/>
      <Navbar/>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/about" element={<AboutMe />} />
      <Route path="/reviews" element={<Review/>} />
    </Routes>
  </BrowserRouter>,
    </div>
  );
}

export default App;
