import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AboutMe from "../../Moleclues/AboutMe/AboutMe";
import BookDetails from "../../Moleclues/BookDetails/BookDetails";
import Navbar from "../../Moleclues/Navabar/Navbar";
import PhotoGallery from "../../Moleclues/PhotoGallery/PhotoGallery";
import classes from "./home.module.scss";

const Home = () => {
  return (
    <div>
    


          <div className={classes.contentContainer}>
            <BookDetails />
            {/* <PhotoGallery /> */}
          </div>
    
        
  
    </div>
  );
};

export default Home;
