import React from "react";
import classes from "./bookDetails.module.scss";
import BookCover from "../../Assestes/BookCover.jpg";
import PhotoGallery from "../PhotoGallery/PhotoGallery";
import Navbar from "../Navabar/Navbar";

const BookDetails = () => {
  return (
    <div className={classes.bookDetailsContainer}>
      <div className={classes.BookDetails}>
        <p className={classes.title}>In Any Given Room</p>
        <p className={classes.desc}>
          <p>
            Will a <span className={classes.bold}>private detective</span> find
            evidence to stop a wedding? How can 16-year- old Anjali escape{" "}
            <span className={classes.bold}> an arranged marriage</span> to a
            much older man she’s never met?
           
          </p>

          <p>
            <span className={classes.bold}>
              {" "}
              Will Indian families trapped in Kuwait City{" "}
            </span>{" "}
            get out before Iraq invades? What does a{" "}
            <span className={classes.bold}>
              {" "}
              mother riding a bike near Boston{" "}
            </span>
            have in common with{" "}
            <span className={classes.bold}>a leper in India?</span>
           {" "}
          </p>

          <p>
            <span className={classes.bold}>
              At a New Delhi party, love, betrayal, and revenge
            </span>{" "}
            swirl as glasses clink and knowing smiles are exchanged.
           
          </p>

          <p>
            <span className={classes.bold}> Kiran, an orphan,</span> is to be
            married off to a stranger. Will she defy the rules and find the love
            stolen from her as a child when she{" "}
            <span className={classes.bold}>
              {" "}
              witnessed her parents’ murders?{" "}
            </span>
           
          </p>

          <p>
            These stories capture the ever-changing loyalties, mysteries,
            enigmas, and surprises that flourish in this complex country. Though
            most areas of India have eagerly embraced cell phones, computers,
            and Apple watches, millions of Indians still go to their wells for
            water and cannot afford to send their children to school.
            <span className={classes.bold}>
              The heart of this book beats for them.
            </span>
          </p>
        </p>

        {/* <button className={classes.findButton}>Find it on Amazon!</button> */}
      </div>

      <div className={classes.bookCoverContainer}>
        <img src={BookCover} className={classes.bookCover} alt="" />
        <button className={classes.findButton}>Find it on Amazon!</button>
      </div>
    </div>
  );
};

export default BookDetails;
